import { Injectable } from '@angular/core';

@Injectable()
export class SoundService {
    private audio = new Audio();

    playSound(soundUrl: string): void {
        this.audio.src = soundUrl;
        this.audio.load();
        this.audio.play().catch(error => {
            console.error('Error playing sound:', error);
        });
    }
}
